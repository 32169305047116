<template>
  <div class="flex flex-col gap-4">
    <!-- FORM NAME -->
    <div class="grid grid-cols-1 md:grid-cols-3 flex-col gap-4">
      <div class="flex flex-col gap-1">
        <label-component label="Name" postfix="*"/>
        <input-component v-model="form.name" type="text" placeholder="Name" :disabled="mode === 'edit'"/>
      </div>

      <!-- fetch types from datamodel -->
      <div class="flex flex-col gap-1 max-h-20 overflow-y-scroll">
        <label-component label="Datensatz-Type" postfix="*"/>

        <select class="select select-sm w-full max-w-xs border-secondary-250" v-model="form.type">
          <option :selected="form.type === type.name" v-for="(type, index) of gatewayStore.getEntityTypes" :key="index" :value="type.name">{{type.title_de}}</option>
        </select>
      </div>

      <div class="flex flex-col gap-1">
        <label-component label="Empfänger" postfix="*"/>
        <input-component v-model="form.email" type="text" placeholder="Empfänger"/>
      </div>
    </div>

    <!-- FIELDS -->
    <div class="flex flex-col gap-2 rounded-md overflow-scroll">
      <div class="inline-flex gap-1 items-center">
        <span class="font-bold text-xl">Felder</span>
        <span class="text-md">({{ form.fields?.length }})</span>
      </div>

      <!-- VIEW -->
      <div v-if="form.fields?.length > 0"
           class="flex flex-col justify-between gap-2 items-center rounded-md pb-2">
        <div v-for="(field, index) of form.fields" :key="index"
             class="flex flex-row w-full items-center rounded border-2 border-secondary-250"
        >
          <!-- infos -->
          <div class="flex flex-col flex-grow p-2 pl-4">
            <!-- Name -->
            <div class="text-md">
              <span class="font-bold">{{ field.name }}</span> ({{ field.type }})
              <span v-if="field.required" class="text-red-500 text-xs">* Pflichtfeld</span>
            </div>

            <!-- Label -->
            <div class="text-sm text-gray-500 dark:text-[#e5e7eb]">{{ field.label }}</div>
          </div>

          <!-- misc -->
          <div class="flex flex-row flex-wrap gap-1 px-1 items-center justify-center">
            <delete-component @delete="deleteField(index)">
              <template #title>
                Feld "{{ form.name }}" löschen
              </template>

              <template #description>
                Möchten Sie das Feld {{ field.name }} wirklich löschen?
              </template>
            </delete-component>

            <upsert-field-component :value="field" mode="edit"/>
          </div>
        </div>
      </div>

      <!-- field modal -->
      <div class="inline-flex justify-center">
        <upsert-field-component @save="addField" mode="add"/>
      </div>
    </div>

    <div class="flex flex-row justify-end">
      <button-component
          @click="update(form)"
      >
        <span class="flex flex-shrink-0 items-center justify-center rounded-full mx-0 h-5 w-5">
          <i class="fa-regular fa-floppy-disk text-sm"></i>
        </span>
        <span class="font-bold text-sm">Speichern</span>
      </button-component>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/ButtonComponent";
import InputComponent from "@/components/InputComponent";
import LabelComponent from "@/components/LabelComponent";
import UpsertFieldComponent from "@/components/forms/UpsertFieldComponent";
import DeleteComponent from "@/components/DeleteComponent.vue";
import {useFormsStore} from "@/stores/forms";
import {mapStores} from "pinia";
import {useGatewayStore} from "@/stores/gateway";
import {useApplicationStore} from "@/stores/application";

export default {
  name: "UpsertFormComponent",
  props: [
    "mode",
    "modelValue"
  ],
  emits: ['upsert'],
  components: {DeleteComponent, UpsertFieldComponent, LabelComponent, InputComponent, ButtonComponent},
  data() {
    return {
      form: {}
    };
  },
  computed: {
    ...mapStores(useFormsStore, useGatewayStore, useApplicationStore)
  },
  async created() {
    this.form = this.modelValue;
    this.form.email = this.form?.summaryRecipients?.join(",");

    await this.gatewayStore.fetchEntityTypes();
  },
  methods: {
    update() {
      this.form.summaryRecipients = this.form?.email?.split(",");
      this.$emit("upsert", this.form);
    },
    addField(field) {
      for (const required of ['name', 'label', 'type']) {
        if (field[required] !== undefined && field[required] !== '')
          continue;

        // TODO: send toast
        return;
      }

      if (!this.form.fields)
        this.form.fields = [];

      this.form.fields.push(Object.assign({}, field));
      this.modal = false;
    },
    deleteField(index) {
      this.form.fields.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.tabs-boxed .tab-active {
  color: white !important;
}
</style>