<template>
  <div class="grid grid-rows rounded-md">
    <div class="flex flex-col sm:flex-row gap-4">
      <div class="flex-grow flex-col">
        <div class="flex flex-wrap items-center">
          <div class="mr-4 text-primary dark:text-white font-bold">
            {{ discussion.username }}
          </div>
          <div class="mr-2 text-sm font-normal text-primary dark:text-white dark:text-text">
            {{ format(discussion.created, 'DD. MMMM YYYY HH:mm') }}
          </div>
          <div class="inline-flex text-secondary-350 dark:text-secondary-100"> (
            <div class="flex items-center gap-2">
              <div class="inline-flex gap-[1px] items-center">
                <i class="fa-solid fa-star fa-w-18 text-xs"></i>
                {{ discussion.likes?.length ?? 0 }}
              </div>
              <div class="inline-flex items-center gap-[5px]">
                <img class="h-3 w-3 mt-[2px]"
                     src="https://api.venus.bayern/assets/ploetzblog/icons/answer_icon.svg"
                     alt="Kommentare icon">
                {{ discussion.answers?.length ?? 0 }}
              </div>
            </div>
            )
          </div>
        </div>

        <!-- keywords -->
        <div class="">
          <ul v-if="discussion.keywords?.length > 0">
            <li v-for="keyword in discussion.keywords" :key="keyword.key_value"
                class="cursor-pointer inline-block px-1.5 py-0.5 mr-1 rounded-md bg-secondary-150 dark:bg-secondary-250 dark:text-secondary w-auto text-xs hover:text-primary"
            >
              {{ keyword.title_de }}
            </li>
          </ul>
        </div>

        <div class="text-secondary dark:text-white">
          <div class="break-all" v-html="discussion.discussion?.replaceAll(/\n/g, '<br>')"></div>
        </div>

        <div class="m-auto flex flex-wrap items-center justify-end gap-4 pt-2">
          <button
              class="text-secondary inline-flex justify-around py-1 px-6 items-center gap-2 border border-secondary-350 text-xs">
            <img class="h-5 w-5" src="https://api.venus.bayern/assets/ploetzblog/icons/answer_icon.svg" alt="Kommentare icon"><span
              class="uppercase">Antworten</span></button>
          <div class="flex flex-shrink gap-2">
            <button class="cursor-pointer text-xl text-primary">
              <svg class="svg-inline--fa fa-star fa-w-18 self-center" aria-hidden="true" focusable="false"
                   data-prefix="far" data-icon="star" role="img" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 576 512">
                <path class="" fill="currentColor"
                      d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="m-auto">
        <div class="flex flex-col gap-2 w-28 sm:w-40">
          <img
              v-for="(image, index) of discussion.images" :key="index"
              :src="`https://${image.src}`"
              :alt="image.id"
              class="object-cover aspect-square w-28 h-28 sm:w-40 sm:h-40"
              :class="{'hidden': selectedImage !== index}"
              loading="lazy"
          >

          <div class="flex flex-wrap gap-2 justify-center">
            <button v-for="(image, index) of discussion.images" :key="index"
                    class="w-6 h-6 inline-flex justify-center items-center rounded-full cursor-pointer text-secondary bg-primary-250"
                    @click="selectedImage = index"
            >
              {{ index + 1 }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <div class="grid grid-rows-1"><!----></div><!----></div>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "DiscussionPreview",
  props: {
    discussion: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedImage: 0
    }
  },
  methods: {
    format(time, format = 'DD.MM.YYYY HH:mm') {
      return moment(time).locale('de').tz('Europe/Berlin').format(format);
    },
  }
}
</script>
