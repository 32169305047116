<template>
  <button-component v-if="mode === 'add'" @click="modal = true">
    <span
      class="flex flex-shrink-0 items-center justify-center rounded-full mx-0 h-5 w-5"
    >
      <i class="fa-solid fa-plus text-sm"></i>
    </span>
    <span class="font-bold text-sm">Feld hinzufügen</span>
  </button-component>

  <button-component
    v-else-if="mode === 'edit'"
    fa-icon="fa-regular fa-pen"
    background-color="bg-transparent"
    color="text-green-600 dark:text-emerald-500"
    @click="modal = true"
  />

  <modal-component v-if="modal" v-model="modal">
    <template #body>
      <div class="text-center sm:text-left flex flex-col gap-4">
        <!-- title -->
        <div id="modal-title" class="flex flex-row gap-2">
          <div
            class="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full sm:mx-0"
          >
            <span v-if="mode === 'add'"
              ><i class="fa-regular fa-plus text-secondary dark:text-white"></i
            ></span>
            <span v-else
              ><i class="fa-solid fa-pen text-secondary dark:text-white"></i
            ></span>
          </div>

          <div>
            <div class="text-lg font-medium leading-6">
              <span v-if="mode === 'add'">Neues Feld hinzufügen</span>
              <span v-else>Feld verändern</span>
            </div>

            <div class="text-xs text-red-500">
              Felder mit einem * sind Pflichtfelder!
            </div>
          </div>
        </div>

        <!-- content -->
        <div class="flex flex-col gap-2">
          <div class="grid grid-cols-2 justify-between gap-4 items-center">
            <label-component
              label="Name"
              postfix="*"
              postfix-color="text-red-500"
            />
            <input-component v-model="field.name" placeholder="Name" />
          </div>

          <div class="grid grid-cols-2 justify-between gap-4 items-center">
            <label-component
              label="Label"
              postfix="*"
              postfix-color="text-red-500"
            />
            <input-component v-model="field.label" placeholder="Label" />
          </div>

          <div class="grid grid-cols-2 justify-between gap-4 items-center">
            <label-component
              label="KG-Feldname"
              postfix="*"
              postfix-color="text-red-500"
            />
            <input-component
              v-model="field.kgFieldName"
              placeholder="Feldname"
            />
          </div>

          <div class="grid grid-cols-2 justify-between gap-4 items-center">
            <label-component
              label="Type"
              postfix="*"
              postfix-color="text-red-500"
            />
            <select
              class="select select-sm w-full border-secondary-250 border-[0.5px]"
              v-model="field.type"
            >
              <option
                v-for="(typeKey, index) of Object.keys(types)"
                :value="types[typeKey]"
                :key="index"
              >
                {{ typeKey }}
              </option>
            </select>
          </div>

          <div class="grid grid-cols-2 gap-4 items-center">
            <label-component label="Pflichtfeld" />
            <input-component type="checkbox" v-model="field.required" />
          </div>

          <div class="grid grid-cols-2 gap-4 items-center">
            <label-component label="Übersicht" />
            <input-component type="checkbox" v-model="field.summary" />
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <button type="button" class="button" @click="modal = false">
        Abbrechen
      </button>
      <button type="button" class="button" @click="addField()">
        <span v-if="mode === 'add'">Hinzufügen</span>
        <span v-if="mode === 'edit'">Speichern</span>
      </button>
    </template>
  </modal-component>
</template>

<script>
import { defineComponent } from "vue";
import ModalComponent from "@/components/ModalComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import InputComponent from "@/components/InputComponent.vue";
import { useNotificationsStore } from "@/stores/notifications.store";
import { mapStores } from "pinia";

export default defineComponent({
  name: "UpsertFieldComponent",
  props: ["mode", "value"],
  emits: ["save"],
  components: {
    InputComponent,
    ButtonComponent,
    LabelComponent,
    ModalComponent,
  },
  data() {
    return {
      modal: false,
      field: {},
      types: {
        Alphanumerisch: "string",
        Numerisch: "number",
        Wahrheitswert: "boolean",
        Datum: "date",
        Bild: "image",
        List: "array",
      },
    };
  },
  created() {
    if (this.mode !== "add" && this.mode !== "edit")
      console.error('UpsertFieldComponent: mode must be "add" or "edit"');

    if (this.value) this.field = this.value;
  },
  computed: {
    ...mapStores(useNotificationsStore),
  },
  methods: {
    addField() {
      for (const required of ["name", "label", "kgFieldName", "type"]) {
        if (!this.field[required]) {
          this.notificationsStore.push({
            type: "error",
            icon: "far fa-check-circle",
            title: "Fehler beim Datenabruf",
            message: `Bitte fülle alle Pflichtfelder aus. Das Feld "${required}" fehlt.`,
            duration: 10000,
          });
          return;
        }
      }

      this.$emit("save", Object.assign({}, this.field));

      if (this.mode === "add") this.field = {};

      this.modal = false;
    },
  },
});
</script>

<style scoped></style>
<script setup></script>
