<template>
  <loading-component>
    <template #content>
      <div class="content-view-container">
        <div class="flex flex-row items-center gap-4">
          <span class="text-secondary text-2xl font-bold">Formular erstellen</span>
        </div>

        <upsert-form-component v-model="form" @upsert="save"/>
      </div>
    </template>
  </loading-component>
</template>

<script>
import {useFormsStore} from "@/stores/forms";
import {mapStores} from "pinia";
import LoadingComponent from "@/components/LoadingComponent.vue";
import UpsertFormComponent from "@/components/forms/UpsertFormComponent.vue";
import Notifications from "@/utility/notifications";

export default {
  name: "FormCreateView",
  components: {UpsertFormComponent, LoadingComponent},
  data() {
    return {
      loading: false,
      form: {
        fields: []
      }
    }
  },
  computed: {
    ...mapStores(useFormsStore)
  },
  async created() {
  },
  methods: {
    async save(form) {
      for (const required of ['name', 'type', 'summaryRecipients', 'fields']) {
        if (form[required] !== undefined && form[required] !== '')
          continue;

        Notifications.error(`Bitte fülle alle Felder aus. Das Feld "${required}" fehlt.`);
        return;
      }

      await this.formsStore.save(form).then((response) => {
        Notifications.success("Formular erstellt");
        this.$router.push(`/forms/${response._id}`);
      }).catch((error) => {
        // send toast
        if (error.response?.status === 409) {
          Notifications.error(`Das Formular ${form.name} existiert bereits.`);
          return;
        }

        Notifications.error(`Das Formular konnte nicht erstellt werden.`);
      });
    },
  }
}
</script>