<template>
  <div class="flex flex-col items-end text-secondary z-20 p-4 rounded-md relative"
       @mouseleave="usersDialog = false"
  >
    <div class="flex-grow text-right cursor-pointer drop-shadow-2xl"
         @mouseover="usersDialog = true"
    >
      <img :src="getAvatar()" alt="Profilbild" class="h-14 rounded-full" />
    </div>

    <div v-if="usersDialog"
         class="card bg-white dark:bg-[#435968] dark:text-white border border-secondary-450 dark:border-secondary-250 rounded-md mt-2 w-80 absolute top-[70px]"
    >
      <div class="body p-0 gap-0">
        <div class="flex flex-col p-4 px-4">
          <!-- Name -->
          <div class="font-bold">{{user?.name}}</div>
          <div class="text-sm">{{user?.mail}}</div>

          <div class="">{{user?.firmation}}</div>

          <div class="font-bold hover:underline cursor-pointer pt-4" @click="switchAccount()">
            <i class="fa-regular fa-user pr-1"></i> Account wechseln
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {useAuthStore} from "@/stores/auth";
import gravatar from "gravatar";

export default defineComponent({
  name: "ProfileComponent",
  data() {
    return {
      usersDialog: false,
      user: undefined
    }
  },
  created() {
    this.user = useAuthStore().getUser;
  },
  methods: {
    getAvatar() {
      return gravatar.url(this.user?.mail, {default: "identicon"});
    },
    switchAccount() {
      useAuthStore().switchAccount();
    }
  }
})
</script>


<style scoped>

</style>