/*
 * Written by Dominik Lippl <dl@venus.bayern> on 27.6.2023
 */

import {defineStore} from "pinia";
import Axios from "@/utility/axios";

export const useGatewayStore = defineStore({
    id: "gateway",
    state: () => ({
        entityTypes: undefined
    }),
    getters: {
        getEntityTypes: (state) => state.entityTypes
    },
    actions: {
        async fetchEntityTypes() {
            return new Promise((resolve, reject) => {
                Axios.get('gateway/entity-types', `${process.env.VUE_APP_GATEWAY_API ? process.env.VUE_APP_GATEWAY_API : "http://localhost:5020"}/api/v1/datamodel/entitytypes`).then((response) => {
                    this.entityTypes = response.data;
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            });
        }
    }
});