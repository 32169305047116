/*
 * Written by Dominik Lippl <dl@venus.bayern> on 27.6.2023
 */

import { defineStore } from "pinia";
import Axios from "@/utility/axios";

export const useVgWortStore = defineStore({
  id: "vgWort",
  state: () => ({
    tokens: undefined,
  }),
  getters: {
    getTokens: (state) => state.tokens,
  },
  actions: {
    async fetch() {
      return new Promise((resolve, reject) => {
        Axios.get("vgwort/fetch", "/api/vgwort/tokens")
          .then((response) => {
            this.tokens = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async get(publicToken) {
      if (this.tokens) {
        const token = this.tokens.find((f) => f.publicToken === publicToken);
        if (token) return token;
      }
    },

    async upload(form) {
      return new Promise((resolve, reject) => {
        Axios.patch("forms/update", "/api/forms", form)
          .then((response) => {
            this.forms?.splice(
              this.forms.findIndex((f) => f._id === form._id),
              1,
              response.data
            );
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});
