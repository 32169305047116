<template>
  <modal-component v-model="show">
    <template #body>
      <progress v-show="loading" class="progress w-full"></progress>
      <div v-if="!loading" class="text-center sm:text-left flex flex-col gap-4">
        <!-- title -->
        <div class="flex flex-col gap-1">
          <div class="text-lg font-bold">
            {{ user.username }} <span class="pl-1">({{ user.id }})</span>
          </div>
          <div class="text-md">
            {{ user.email }}
          </div>

          <div class="text-md">
            <span class="font-bold">{{ user.liked }}</span> Like(s) vergeben, <span class="font-bold">{{
              user.likes
            }}</span> Like(s) erhalten
          </div>

          <div class="text-md">
            Benutzer

            <button @click="lock(user.id)">
              <span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                    :class="[user.locked  ? 'bg-red-400/10 text-red-400 ring-red-400/20' : 'bg-green-500/10 text-green-400 ring-green-500/20']">{{
                  user.locked ? 'gesperrt' : 'nicht gesperrt'
                }}</span>
            </button>
          </div>
        </div>

        <!-- discussions -->
        <div v-if="false" class="flex flex-col gap-2">
          <div class="text-md font-bold">Kommentare</div>

          <div class="flex gap-2 max-h-96 overflow-y-scroll">
            <div v-for="(discussion, index) of user.discussions" :key="index"
                 class="border border-secondary-250 rounded-md p-2">
              <div class="flex flex-col gap-1 text-sm">
                <div>
                  {{ truncate(discussion.discussion, 150) }}
                  <span class="font-bold">Freigegeben</span> <span
                    class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                    :class="[user.locked  ? 'bg-red-400/10 text-red-400 ring-red-400/20' : 'bg-green-500/10 text-green-400 ring-green-500/20']">{{
                    discussion.released ? 'Ja' : 'Nein'
                  }}</span>
                </div>
                <!-- created -->
                <div>
                  <span class="font-bold">{{ format(discussion.created) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import {useDiscussionStore} from "@/stores/discussion";
import {mapStores} from "pinia";
import ModalComponent from "@/components/ModalComponent.vue";
import moment from "moment-timezone";

export default {
  name: "UserEditView",
  components: {ModalComponent},
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapStores(useDiscussionStore)
  },
  data() {
    return {
      show: true,
      loading: false,
      user: undefined
    }
  },
  watch: {
    show(value) {
      if (!value)
        this.$router.push('/discussions/users');
    }
  },
  created() {
    this.loading = true;
    // fetch user
    this.discussionStore.fetchUser(this.id).then((result) => {
      this.user = result;
      this.loading = false;
    });
  },
  methods: {
    lock(id) {
      this.user.locked = !this.user.locked;
      this.discussionStore.lockUser(id, this.user.locked);
    },
    truncate(text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
    },
    format(time) {
      return moment(time).tz('Europe/Berlin').format('DD.MM.YYYY HH:mm');
    },
  }
}
</script>