import {createRouter, createWebHistory} from 'vue-router'

import {useAuthStore} from "@/stores/auth";
import OverviewView from "@/views/OverviewView.vue";
import FormsView from "@/views/forms/FormsView.vue";
import VgWordView from "@/views/VgWordView.vue";
import PlainRouterView from "@/components/PlainRouterView.vue";
import FormCreateView from "@/views/forms/FormCreateView.vue";
import FormEditView from "@/views/forms/FormEditView.vue";
import DiscussionsView from "@/views/discussions/DiscussionsView.vue";
import DiscussionEditView from "@/views/discussions/DiscussionEditView.vue";
import UsersView from "@/views/discussions/UsersView.vue";
import UserEditView from "@/views/discussions/UserEditView.vue";
import DiscussionCreateView from "@/views/discussions/DiscussionCreateView.vue";

const routes = [
    {
        path: '/',
        component: OverviewView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/forms',
        component: PlainRouterView,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                name: 'view',
                path: '',
                component: FormsView,
            },
            {
                name: 'create-form',
                path: 'create',
                component: FormCreateView,
            },
            {
                path: ':id',
                component: FormEditView,
                props: true
            }
        ],
    },
    {
        path: '/discussions',
        component: PlainRouterView,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                name: 'discussions',
                path: '',
                component: DiscussionsView,
                children: [
                    {
                        path: 'create',
                        name: 'create',
                        component: DiscussionCreateView,
                        props: true,
                        meta: {
                            refresh: false
                        }
                    },
                    {
                        path: 'create/:parent',
                        name: 'create-with-parent',
                        component: DiscussionCreateView,
                        props: true,
                        meta: {
                            refresh: false
                        }
                    },
                    {
                        path: ':id',
                        name: 'edit',
                        component: DiscussionEditView,
                        props: true,
                        meta: {
                            refresh: false
                        }
                    }
                ]
            },
            {
                path: 'users',
                component: UsersView,
                children: [
                    {
                        path: ':id',
                        component: UserEditView,
                        props: true
                    },
                ]
            }
        ]
    },
    {
        path: '/vgword',
        name: 'vgword',
        component: VgWordView,
        meta: {
            requiresAuth: true
        }
    },
]
const router = createRouter({
    history: createWebHistory("/dashboard"),
    routes
})

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();

    if (to.path === '/auth/callback') {
        const authorization_code = to.query.code;

        await authStore.authCallback(authorization_code).then(() => {
            next({
                path: "/"
            });
        }).catch(() => {
            next({
                path: 'error'
            });
        });

        return;
    } else if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!authStore.isAuthenticated) {
            location.href = authStore.getLoginUrl;
            return;
        }
    }

    next();
});

export default router