<template>
  <Combobox as="div" class="" v-model="selectedKeywords" multiple>
    <ul v-if="selectedKeywords.length > 0 && overview">
      <li v-for="keyword in selectedKeywords" :key="keyword.name"
          class="cursor-pointer inline-block px-2 py-1 mr-1 rounded-md bg-secondary-150 w-auto text-sm hover:text-secondary"
          @click="removeKeyword(keyword.name)"
      >
        {{ keyword.title_de }}

        <i class="ml-1 fa-regular fa-trash"></i>
      </li>
    </ul>

    <div class="relative mt-2">
      <ComboboxInput
          class="bg-secondary-150 border-none input-sm w-full max-w-full"
          @change="query = $event.target.value"
          :displayValue="(keyword) => keyword.title_de"
      />

      <ComboboxButton
          class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
          v-slot="{open}">
        <i v-if="open" class="h-5 w-5 text-gray-400 fa-regular fa-angle-up"></i>
        <i v-else class="h-5 w-5 text-gray-400 fa-regular fa-angle-down"></i>
      </ComboboxButton>

      <ComboboxOptions
          class="bg-secondary-150 absolute z-10 max-h-60 w-full overflow-auto rounded-b-md text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        <ComboboxOption
            v-for="keyword in filteredKeywords"
            :key="keyword.id"
            :value="keyword"
            as="template"
            :disabled="selectedKeywords.length >= keywordsLimit ? !contains(keyword.name) : false"
            v-slot="{ active, selected }"
        >
          <li class="cursor-pointer"
              :class="['relative cursor-default select-none py-2 pl-3 pr-9 text-secondary', active ? 'bg-secondary-250' : '']">
            <span class="block truncate" :class="{'font-semibold text-primary': selected}">
              {{ keyword.title_de }}
            </span>

            <span v-if="selected"
                  class="absolute inset-y-0 right-0 flex items-center pr-4 text-primary"
            >
              <i class="h-5 w-5 tw-text-primary dark:tw-text-white fa-regular fa-check"></i>
            </span>
          </li>

        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script>
import {Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions} from "@headlessui/vue";
import {mapStores} from "pinia";
import {useDiscussionStore} from "@/stores/discussion";

export default {
  name: "KeywordsComponent",
  components: {ComboboxInput, ComboboxOptions, ComboboxOption, ComboboxButton, Combobox},
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    keywordsLimit: {
      type: Number,
      default: 5
    },
    overview: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapStores(useDiscussionStore),
    filteredKeywords() {
      return this.query === '' ? this.keywords : this.keywords.filter((keyword) => {
        return keyword.title_de.toLowerCase().includes(this.query.toLowerCase())
      });
    }
  },
  data() {
    return {
      query: '',
      keywords: [],
      selectedKeywords: [] //this.modelValue
    }
  },
  watch: {
    selectedKeywords(value) {
      this.$emit('update:modelValue', value);
    }
  },
  async created() {
    this.keywords = await this.discussionStore.loadKeywords();
    this.selectedKeywords = this.keywords.filter((keyword) => {
      return this.modelValue.findIndex((modelKeyword) => modelKeyword.key_value === keyword.key_value) !== -1;
    })
  },
  methods: {
    contains(name) {
      return this.selectedKeywords.findIndex((keyword) => keyword.name === name) !== -1;
    },
    removeKeyword(name) {
      this.selectedKeywords.splice(this.selectedKeywords.findIndex((keyword) => keyword.name === name), 1);
    },
  }
}
</script>