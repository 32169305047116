<template>
  <div class="bg-white border border-secondary dark:border-secondary-250 dark:bg-secondary-450 text-secondary dark:text-white rounded-md min-h-full p-9">
    <!-- header -->
    <div class="text-2xl font-bold">Formulare</div>

    <table class="min-w-full table-fixed divide-y divide-gray-300">
      <!-- header -->
      <thead>
      <tr>
        <th scope="col" class="py-3.5 px-2 text-left text-sm font-semibold text-secondary dark:text-white">
          ID
        </th>
        <th scope="col" class="py-3.5 px-2 text-left text-sm font-semibold text-secondary dark:text-white">Name</th>
        <th scope="col" class="py-3.5 px-2 text-left text-sm font-semibold text-secondary dark:text-white">Type</th>
        <th scope="col" class="py-3.5 px-2 text-left text-sm font-semibold text-secondary dark:text-white">Felder</th>
        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3"></th>
      </tr>
      </thead>
      <!-- table body -->
      <tbody class="divide-y divide-gray-200 text-secondary dark:text-white">
      <tr v-for="(form, index) of formsStore.getForms" v-bind:key="index">
        <!-- id -->
        <td class="whitespace-nowrap px-2 py-4 text-sm">
          {{ form._id }}
        </td>

        <!-- name -->
        <td class="whitespace-nowrap px-2 py-4 text-sm font-bold">
          {{ form.name }}
        </td>

        <!-- type -->
        <td class="whitespace-nowrap px-2 py-4 text-sm">
          {{ form.type }}
        </td>

        <!-- fields -->
        <td class="whitespace-nowrap px-2 py-4 text-sm">
          {{ form.fields.length }}
        </td>

        <td class="flex flex-row gap-2 justify-end">
          <button-component fa-icon="fa-regular fa-pencil" color="dark:text-white" background-color="dark:bg-transparent" @click="$router.push(`/forms/${form._id}`)"/>
          <delete-component @delete="deleteForm(form._id)">
            <template #title>
              Formular "{{form.name}}" löschen
            </template>

            <template #description>
              Möchten Sie das Formular {{form.name}} wirklich löschen?
              <br>
              <span class="italic">Gelöschte Formulare könenn nicht wiederhergestellt werden!</span>
            </template>
          </delete-component>
        </td>
      </tr>
      </tbody>
    </table>

    <!-- misc -->
    <div class="flex flex-row gap-2 justify-end">
      <button-component @click="$router.push(`/forms/create`)">Hinzufügen</button-component>
    </div>
  </div>
</template>

<script>
import {useFormsStore} from "@/stores/forms";
import {mapStores} from "pinia";
import ButtonComponent from "@/components/ButtonComponent.vue";
import DeleteComponent from "@/components/DeleteComponent.vue";

export default {
  name: "FormsView",
  components: {DeleteComponent, ButtonComponent},
  data() {
    return {loading: false}
  },
  async created() {
    this.loading = true;
    await this.formsStore.fetch();
    this.loading = false;
  },
  computed: {
    ...mapStores(useFormsStore)
  },
  methods: {
    deleteForm() {}
  }
}
</script>