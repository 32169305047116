<template>
  <div class="drawer lg:drawer-open">
    <input id="my-drawer-2" type="checkbox" class="drawer-toggle" />

    <div class="drawer-content flex flex-col h-screen">
      <!-- Page content here -->
      <label
        for="my-drawer-2"
        class="drawer-button lg:hidden absolute left-2 top-2 p-4 text-secondary"
      >
        <i class="fa-solid fa-bars fa-2xl"></i>
      </label>

      <!-- upper right tool box -->
      <div class="absolute top-2 right-2 flex flex-row gap-2">
        <!-- dark-mode switch -->
        <label class="swap swap-rotate text-secondary dark:text-white">
          <!-- this hidden checkbox controls the state -->
          <input type="checkbox" class="hidden" @click="switchDarkMode()" />

          <!-- sun icon -->
          <svg
            class="swap-on fill-current w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z"
            />
          </svg>

          <!-- moon icon -->
          <svg
            class="swap-off fill-current w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z"
            />
          </svg>
        </label>

        <profile-component></profile-component>
      </div>

      <!-- CONTENT VIEW -->
      <div
        class="bg-primary-100 dark:bg-secondary-350 h-full content-view-padding overflow-scroll"
      >
        <router-view></router-view>
      </div>
    </div>

    <div class="drawer-side">
      <label for="my-drawer-2" class="drawer-overlay"></label>

      <ul
        class="flex flex-col gap-2 p-0 w-80 h-full text-base-content bg-white dark:bg-secondary-450 border-r-2 border-secondary-350 dark:border-secondary-350"
      >
        <li
          class="pr-4 items-center justify-center min-h-[150px] inline-flex pb-8"
        >
          <img
            class="w-[225px] object-contain px-6 pb-0 mx-auto block dark:hidden"
            src="https://api.venus.bayern/assets/ploetzblog/logos/ketten-rgb.svg"
            alt="PLoetzblog"
          />
          <img
            class="w-[225px] object-contain px-6 pb-0 mx-auto hidden dark:block"
            src="https://api.venus.bayern/assets/ploetzblog/logos/ketten-white.svg"
            alt="PLoetzblog"
          />
        </li>

        <li
          v-for="(menuItem, index) of menuItems"
          v-bind:key="index"
          class="flex flex-col rounded-l-md ml-4 text-secondary font-bold"
          :class="{
            'bg-secondary-350 text-white dark:bg-secondary-350 dark:text-white font-bold':
              isCurrent(menuItem.path),
            'text-secondary dark:text-white hover:text-white hover:bg-secondary-350 hover:dark:bg-secondary-350':
              !isCurrent(menuItem.path),
          }"
          @click="currentMenuItem = menuItem"
        >
          <router-link
            :to="`${menuItem.path}`"
            class="flex flex-row items-center px-4 py-3 text-base"
          >
            <!-- icon -->
            <div class="w-8">
              <i :class="menuItem.icon" class="w-10 text-xl"></i>
            </div>

            <!-- text -->
            <div class="font-bold text-base uppercase">
              {{ menuItem.name }}
            </div>
          </router-link>

          <ul v-if="menuItem.children" class="ml-4">
            <li v-for="(child, index) of menuItem.children" :key="index">
              <router-link
                  :to="`${menuItem.path}${child.path}`"
                  class="flex flex-row items-center text-base py-3"
              >
                <div class="w-16 flex justify-end">
                  <i class="fa-regular fa-users w-10 text-xl"></i>
                </div>

                <!-- text -->
                <div class="font-bold text-base uppercase">
                  {{ child.name }}
                </div>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>

  <div><NotificationSystem /></div>
</template>

<script>
import { mapStores } from "pinia";
import { useAuthStore } from "@/stores/auth";
import ProfileComponent from "@/components/ProfileComponent.vue";
import NotificationSystem from "@/components/notification/NotificationSystem.vue";

export default {
  components: {
    NotificationSystem,
    ProfileComponent,
  },
  computed: {
    ...mapStores(useAuthStore),
  },
  data() {
    return {
      menuItems: [
        {
          name: "Übersicht",
          icon: "fa-regular fa-table-columns",
          path: "/",
        },
        {
          name: "Formulare",
          icon: "fa-regular fa-file-pen",
          path: "/forms",
        },
        {
          name: "Discussions",
          icon: "fa-regular fa-comments",
          path: "/discussions",
          children: [
            {
              name: 'Benutzer',
              path: '/users'
            }
          ]
        },
        {
          name: "VG Wort",
          icon: "fa-regular fa-file-word",
          path: "/vgword",
        },
      ],
      currentMenuItem: undefined,
      loading: false,
      style: {},
    };
  },
  created() {
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  },
  methods: {
    isCurrent(path) {
      return (
        this.$route.path === path ||
        !!this.$route.matched.find((route) => route.path === path)
      );
    },
    switchDarkMode() {
      if (localStorage.getItem("theme") === "dark") {
        localStorage.setItem("theme", "light");
        document.documentElement.classList.remove("dark");
      } else {
        localStorage.setItem("theme", "dark");
        document.documentElement.classList.add("dark");
      }
    },
  },
};
</script>

<style>
.content-view-padding {
  //padding: 6rem max(max(1rem, 5vw), calc((100% - 110ch) / 2)) 1.5rem;
  padding: 6rem max(max(1rem, 3vw), calc((100% - 150ch) / 2)) 1.5rem !important;
}
</style>
