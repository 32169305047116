<template>
  <div
      class="bg-white border border-secondary dark:border-secondary-250 dark:bg-secondary-450 text-secondary dark:text-white rounded-md min-h-full p-9"
  >
    <!-- header -->
    <div class="text-2xl font-bold">VG Wort Zählmarken</div>

    <!-- misc -->
    <div class="flex justify-end my-4 gap-4">
      <input
          type="file"
          class="file-input file-input-bordered"
          @change="handleFiles"
      />
      <button-component class="my-auto" v-if="file" @click="uploadTokens"
      >Marken importieren
      </button-component
      >
      <button-component class="my-auto" @click="downloadExport"
      >Export
      </button-component
      >
    </div>

    <div v-if="!loading">
      <p class="mb-5">
        {{ vgWortStore.getTokens.length }} Zählmarken
        <span
            :class="{
            'text-red-500': remainingCount < 100,
          }"
        >(davon {{ remainingCount }} noch verfügbar)</span
        >
      </p>

      <table class="min-w-full table-fixed divide-y divide-gray-300">
        <!-- header -->
        <thead>
        <tr>
          <th scope="col" class="px-2 py-3.5 text-left text-sm font-semibold text-secondary dark:text-white">Zählmarke</th>
          <th scope="col" class="px-2 py-3.5 text-left text-sm font-semibold text-secondary dark:text-white">Überschrift</th>
          <th scope="col" class="px-2 py-3.5 text-left text-sm font-semibold text-secondary dark:text-white">Zeichen</th>
          <th scope="col" class="px-2 py-3.5 text-left text-sm font-semibold text-secondary dark:text-white">Aufrufe</th>
          <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3"></th>
          <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3"></th>
        </tr>
        </thead>
        <!-- table body -->
        <tbody class="divide-y divide-gray-200 dark:text-white">
        <tr v-for="(token, index) of vgWortStore.getTokens.filter((t) => t.url)" v-bind:key="index">
          <!-- id -->
          <td class="whitespace-nowrap px-2 py-4 text-sm text-gray-500 dark:text-white">
            {{ token.publicToken }}
          </td>

          <!-- name -->
          <td class="px-2 py-4 text-sm font-bold break-all">
            {{ token.headline }}
          </td>

          <!-- chars -->
          <td class="whitespace-nowrap px-2 py-4 text-sm text-gray-500 dark:text-white">
            {{ token.charCount ?? "?" }}
          </td>

          <!-- calls -->
          <td class="whitespace-nowrap px-2 py-4 text-sm text-primary font-bold">
            {{ token.calls ?? 0 }}
          </td>

          <!-- token -->
          <td v-if="token.url">
            <a :href="token.url" target="_blank">
              <i class="fas fa-arrow-up-right-from-square"></i>
            </a>
          </td>
          <td
              v-if="token.url"
              @click="crawl(token.publicToken)"
              class="cursor-pointer"
          >
            <i class="fas fa-file-magnifying-glass"></i>
          </td>
        </tr>
        </tbody>
      </table>

      <table v-if="false" class="table table-pin-rows table-pin-cols overflow-y-scroll">
        <thead class="text-secondary-50">
        <tr class="border-secondary bg-primary text-white">
          <th style="width: 40px">Zählmarke</th>
          <th>Überschrift</th>
          <th>Zeichen</th>
          <th>Aufrufe</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(token, index) of vgWortStore.getTokens.filter((t) => t.url)"
            v-bind:key="index"
        >
          <td style="width: 40px">{{ token.publicToken }}</td>
          <td>{{ token.headline }}</td>
          <td>{{ token.charCount ?? "?" }}</td>
          <td>{{ token.calls ?? "?" }}</td>
          <td v-if="token.url">
            <a :href="token.url" target="_blank"
            ><i class="fas fa-arrow-up-right-from-square"></i
            ></a>
          </td>
          <td
              v-if="token.url"
              @click="crawl(token.publicToken)"
              class="cursor-pointer"
          >
            <i class="fas fa-file-magnifying-glass"></i>
          </td>
        </tr>
        </tbody>
      </table>


    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import ButtonComponent from "@/components/ButtonComponent.vue";
import {useVgWortStore} from "@/stores/vgwort";
import DownloadService from "@/utility/download-service";
import Axios from "@/utility/axios";
import {useNotificationsStore} from "@/stores/notifications.store";

export default {
  name: "VgWordView",
  components: {ButtonComponent},
  data() {
    return {loading: false, file: null};
  },
  async created() {
    this.loading = true;
    await this.vgWortStore.fetch();
    this.loading = false;
  },
  computed: {
    ...mapStores(useVgWortStore, useNotificationsStore),
    remainingCount: function () {
      return this.vgWortStore.getTokens.filter((t) => !t.url).length;
    },
    relevantCount: function () {
      return this.vgWortStore.getTokens.filter((t) => !t.url).length;
    },
  },
  methods: {
    deleteForm() {
    },
    handleFiles(event) {
      this.file = event.target.files[0];
    },
    uploadTokens() {
      let formData = new FormData();
      formData.append("file", this.file);
      Axios.post("token_upload", "/api/vgwort/tokens", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
          .then((res) => {
            this.notificationsStore.push({
              type: "success",
              icon: "far fa-check-circle",
              title: "Tokens hinzugefügt",
              message: `Insgesamt ${res.data.createdCount} neue Tokens.`,
              duration: 2000,
            });
            this.vgWortStore.fetch();
            this.file = null;
          })
          .catch((e) => {
            this.notificationsStore.push({
              type: "error",
              icon: "far fa-warning",
              title: "Es ist ein Fehler aufgetreten",
              message: e,
              duration: 10000,
            });
          });
    },
    crawl(publicToken) {
      Axios.post("crawl_" + publicToken, `/api/vgwort/tokens/stats`, {
        publicToken,
      })
          .then(() => {
            this.notificationsStore.push({
              type: "success",
              icon: "far fa-check-circle",
              title: "Daten wurden abgerufen",
              duration: 2000,
            });
            this.vgWortStore.fetch();
          })
          .catch((e) => {
            this.notificationsStore.push({
              type: "error",
              icon: "far fa-check-circle",
              title: "Fehler beim Datenabruf",
              message: e,
              duration: 10000,
            });
          });
    },
    downloadExport() {
      DownloadService.downloadWithAxios(
          "/api/vgwort/tokens/export",
          "zaehlmarken_" + new Date().toISOString() + ".ods",
          true
      );
    },
  },
};
</script>
