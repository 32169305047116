<template>
  <modal-component v-model="show">
    <template #body>
      <progress v-show="loading" class="progress w-full"></progress>
      <div v-if="!loading" class="text-center sm:text-left flex flex-col gap-4">
        <!-- title -->
        <div class="flex flex-col">
          <div class="text-lg font-bold">
            Konversation
            <button class="mt-1" @click="release()">
              <span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                    :class="[discussion?.released  ? 'bg-green-500/10 text-green-400 ring-green-500/20': 'bg-red-400/10 text-red-400 ring-red-400/20']">{{
                  discussion?.released ? 'Freigegeben' : 'Nicht Freigegeben'
                }}</span>
            </button>
          </div>
          <div class="text-md text-gray-400 dark:text-gray-300">
            {{ discussion?.id }}
          </div>
        </div>

        <!-- information's -->
        <div class="grid grid-cols-3 gap-1">
          <!-- author -->
          <div class="flex flex-col">
            <div class="font-bold">Author</div>
            <div class="text-sm">{{ discussion?.username }}</div>
            <div class="text-sm">{{ discussion?.email }}</div>
          </div>

          <!-- author -->
          <div class="flex flex-col">
            <div class="font-bold">Entity</div>
            <p class="text-secondary dark:text-white break-all text-sm" v-html="discussion?.entity?.name"></p>
            <div class="text-sm break-all">{{ discussion?.entity?.id }}</div>
          </div>

          <!-- created and updated -->
          <div class="flex flex-col">
            <div class="font-bold">Erstellt</div>
            <div class="text-sm">{{ format(discussion?.created) }}</div>

            <div v-show="discussion?.updated" class="font-bold">Aktualisiert</div>
            <div v-show="discussion?.updated" class="text-sm">{{ format(discussion?.updated) }}</div>
          </div>

          <!-- likes and answer -->
          <div class="flex flex-col">
            <div class="font-bold">Likes</div>
            <div class="text-sm">{{ discussion?.likes?.length ?? 0 }}</div>

            <div class="font-bold">Antworten</div>
            <div class="text-sm">{{ discussion?.answers?.length ?? 0 }}</div>
          </div>
        </div>

        <!-- discussion editor -->
        <div class="flex flex-col gap-2">
          <div class="text-lg font-bold">Konversation</div>

          <!-- tabs -->
          <div>
            <!-- writer tab -->
            <div v-show="view === 'write'" id="tabs-1-panel-1" class="-m-0.5 rounded-lg p-0.5"
                 aria-labelledby="tabs-1-tab-1" role="tabpanel" tabindex="0">
              <textarea rows="5"
                        name="comment"
                        id="comment"
                        class="bg-transparent text-secondary dark:text-white block w-full border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-100 sm:text-sm sm:leading-6"
                        placeholder="Kommentar hinzufügen"
                        v-model="discussion.discussion"
              ></textarea>
            </div>

            <!-- preview tab -->
            <div v-show="view === 'preview'" class="-m-0.5 p-0.5">
              <div
                  class="block p-2 w-full bg-transparent py-1.5 text-secondary dark:text-white border boder-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6">
                <discussion-preview :discussion="discussion"></discussion-preview>
              </div>
            </div>
          </div>

          <!-- tab control -->
          <div class="flex gap-2 justify-end" aria-orientation="horizontal" role="tablist">
            <button-component @click="view = 'write'" :selected="view === 'write'">
              Editieren
            </button-component>

            <button-component @click="view = 'preview'" :selected="view === 'preview'">
              Preview
            </button-component>
          </div>
        </div>

        <!-- keywords -->
        <div>
          <label class="text-secondary dark:text-white font-semibold">
            Schlüsselwörter ({{ discussion.keywords.length }}/{{ keywordsLimit }})
          </label>

          <keywords-component v-model="discussion.keywords" :overview="true"></keywords-component>
        </div>


        <!-- images -->
        <div v-if="discussion?.images?.length > 0" class="flex flex-col gap-1">
          <div class="text-lg font-bold">Bilder</div>
          <div class="flex flex-row gap-2 flex-wrap">
            <div v-for="(image, index) of discussion?.images" :key="index" class="w-32 h-32 relative">
              <img :src="`https://${image.src}`" :alt="image.id" class="w-full h-full object-cover rounded-sm">

              <!-- delete icon -->
              <button
                  @click="removeImage(index)"
                  class="flex items-center justify-center absolute top-2 right-2 text-secondary bg-white rounded-full w-7 h-7"
              >
                <i class="fa-light fa-trash text-md"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="text-sm text-gray-500 dark:text-white">
          Hinweis: Gespeicherte Änderungen können nicht rückgängig gemacht werden.
        </div>

        <!-- answers -->
        <div v-if="false" class="flex flex-col gap-1">
          <div class="text-lg font-bold">Antworten</div>
          <div v-for="(answer, index) in discussion.answers" :key="index"
               class="flex flex-col gap-1 border border-gray-300 p-2">
            <div v-if="false">{{ answer.username }} <span class="text-sm text-gray-400">{{ answer.email }}</span></div>
            <div>{{ answer.username }} <span class="text-sm text-gray-400">{{ answer.email }}</span></div>
            <div> {{ truncate(answer.discussion, 150) }}</div>

            <div class="flex justify-end">
              <button @click="$router.push(`/discussions/${answer.id}`)">
                <i class="fa-light fa-pen-to-square"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex flex-row flex-grow justify-between gap-8">
        <button-component @click="show = false">
          Schließen
        </button-component>

        <button-component @click="deleteDiscussion()">
          Löschen
        </button-component>

        <button-component @click="save()">
          Speichern
        </button-component>
      </div>
    </template>
  </modal-component>
</template>

<script>
import {useDiscussionStore} from "@/stores/discussion";
import {mapStores} from "pinia";
import ModalComponent from "@/components/ModalComponent.vue";
import moment from "moment-timezone";
import ButtonComponent from "@/components/ButtonComponent.vue";
import DiscussionPreview from "@/views/discussions/DiscussionPreview.vue";

import KeywordsComponent from "@/components/discussions/KeywordsComponent.vue";

export default {
  name: "DiscussionEditView",
  components: {
    KeywordsComponent,
    DiscussionPreview, ButtonComponent, ModalComponent
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapStores(useDiscussionStore)
  },
  data() {
    return {
      show: true,
      loading: true,
      discussion: {},
      view: 'write',
      selectedImage: 0,

      keywordsLimit: 5
    }
  },
  watch: {
    show(value) {
      if (!value)
        this.$router.push('/discussions');
    }
  },
  async created() {
    // fetch discussion
    this.discussionStore.fetch(this.id).then((result) => {
      this.discussion = result;
      this.loading = false;
    });
  },
  methods: {
    save() {
      this.discussionStore.update(this.discussion).then(() => {
        this.show = false;
      });
    },
    deleteDiscussion() {
      this.discussionStore.delete([this.discussion.id]).then(() => {
        this.show = false;
      });
    },
    removeImage(index) {
      this.discussion.images.splice(index, 1);
    },
    release() {
      this.discussionStore.release(this.id, this.discussion.released).then( () => {
        this.discussion.released = !this.discussion.released;
      });
    },
    truncate(text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
    },
    format(time, format = 'DD.MM.YYYY HH:mm') {
      return moment(time).locale('de').tz('Europe/Berlin').format(format);
    }
  }
}
</script>
