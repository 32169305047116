/*
 * Written by Dominik Lippl <dl@venus.bayern> on 19.10.2023
 */

import {defineStore} from "pinia";
import Axios from "@/utility/axios";

export const useDiscussionStore = defineStore({
    id: "discussion",
    state: () => ({
        DISCUSSION_API: (process.env.VUE_APP_DISCUSSION_API ? process.env.VUE_APP_DISCUSSION_API : "https://discussion.ploetzblog.de") + "/api",
        discussion: [],
        entities: [],
    }),
    getters: {
        getDiscussions: (state) => state.discussions,
        getEntities: (state) => state.entities,
    },
    actions: {
        loadByFilter(filter) {
            return new Promise((resolve, reject) => {
                Axios.post('discussion/query', `${this.DISCUSSION_API}/discussions/query`, filter)
                    .then((response) => {
                        resolve(response.data);
                    }).catch((err) => reject(err));
            });
        },
        loadEntities() {
            return new Promise((resolve, reject) => {
                Axios.post('discussion/entities', `${this.DISCUSSION_API}/discussions/query`, {
                    properties: [
                        {
                            property: 'entity',
                            type: 'collect'
                        }
                    ],
                    options: [
                        {option: 'single', value: true}
                    ]
                }).then((response) => {
                    this.entities = response.data;
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        async loadKeywords() {
            return (await Axios.get('discussions/keywords', `${this.DISCUSSION_API}/discussions/keywords`)).data;
        },
        async create(discussion) {
            return (await Axios.post('discussions/create', `${this.DISCUSSION_API}/modules/ploetzblog/discussion`, {
                ...discussion,
                discussion: discussion.discussion.replaceAll(/\n/g, '<br>'),
            }).catch()).data;
        },
        async delete(ids) {
            return (await Axios.delete('discussions/delete', `${this.DISCUSSION_API}/discussions`, {
                ids
            }).catch()).data;
        },
        async searchUser(search) {
            return (await Axios.post('discussions/user/search', `${this.DISCUSSION_API}/user/search`, {
                search
            }).catch(() => {
            }))?.data;
        },
        async fetch(id) {
            return new Promise((resolve) => {
                Axios.post('discussion', `${this.DISCUSSION_API}/discussions/query`, {
                    filter: [
                        {
                            key: 'id',
                            value: id
                        }
                    ],
                    properties: [
                        {property: 'id'},
                        {property: 'type'},
                        {property: 'entity'},
                        {property: 'released'},
                        {property: 'accepted'},
                        {property: 'images'},
                        {property: 'created'},
                        {
                            property: 'user',
                            properties: [
                                {property: 'id'},
                                {property: 'type'},
                                {property: 'username'},
                                {property: 'email'},
                                {property: 'created'},
                                {property: 'lastLogin'}
                            ]
                        },
                        {
                            property: 'likes',
                            type: 'collect'
                        },
                        {
                            property: 'answers',
                            type: 'collect'
                        },
                        {
                            property: 'discussion'
                        },
                        {
                            property: 'keywords',
                            type: 'collect'
                        }
                    ],
                    options: [
                        {
                            option: "single",
                            value: "true"
                        },
                        {
                            option: "released",
                            value: "ignore"
                        }
                    ]
                }).then((response) => {
                    resolve(response.data);
                });
            });
        },
        async update(discussion) {
            return (await Axios.patch('discussion/update',
                `${this.DISCUSSION_API}/discussions/${discussion.id}`,
                [
                    {
                        key: 'discussion',
                        value: discussion.discussion
                    },
                    {
                        key: 'keywords',
                        value: discussion.keywords.map((keyword) => keyword.key_value)
                    },
                    {
                        key: 'images',
                        value: discussion.images
                    }
                ]
            )).data;
        },
        async fetchUsers() {
            return (await Axios.get('users/fetch', `${this.DISCUSSION_API}/user/users`)).data.data;
        },
        async fetchUser(id) {
            return (await Axios.get('users/fetch/id', `${this.DISCUSSION_API}/user/${id}`)).data.data;
        },
        async lockUser(id, lock) {
            return (await Axios.patch('users/lock/id', `${this.DISCUSSION_API}/user/${id}/${lock}`)).data.data;
        },
        async release(id, released) {
            await Axios.put(`discussion/release/${id}`, `${this.DISCUSSION_API}/discussions/release/${id}/${released}`);
        },
    }
});
