<template>
  <div class="flex rounded-md">
    <Combobox as="div" class="flex-grow" v-model="selectedUser">
      <div class="relative">
        <ComboboxInput
            class="w-full border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
            @change="userQuery = $event.target.value"
            :display-value="(user) => user.username ? `${user?.username} (${user?.email})` : ''"
        />

        <ComboboxOptions v-if="filteredUsers.length > 0"
                         class="bg-secondary-150 absolute z-10 mt-1 max-h-60 w-full overflow-auto text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <ComboboxOption v-for="user in filteredUsers" :key="user.id" :value="user" as="template"
                          v-slot="{ active, selected }">
            <li :class="['relative cursor-default select-none py-2 pl-3 pr-9 text-secondary', active ? 'bg-secondary-250' : '']">
                        <span :class="['block truncate', selected && 'font-semibold']">
                          {{ user.username }} ({{ user.email }})
                        </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </div>
    </Combobox>

    <button type="button"
            class="relative -ml-px inline-flex items-center gap-x-1.5 px-3 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 bg-white hover:bg-secondary-250 hover:text-white"
            @click="selectedUser = {}"
    >
      <i class="fa-regular fa-xmark"></i>
    </button>
  </div>
</template>

<script>
import {Combobox, ComboboxInput, ComboboxOption, ComboboxOptions} from "@headlessui/vue";
import {debounce} from "lodash";
import {mapStores} from "pinia";
import {useDiscussionStore} from "@/stores/discussion";

export default {
  name: "UserSearchComponent",
  components: {ComboboxOption, ComboboxOptions, ComboboxInput, Combobox},
  props: ['modelValue'],
  emits: ['update:modelValue'],
  data() {
    return {
      userQuery: '',
      filteredUsers: [],
      selectedUser: undefined
    }
  },
  computed: {
    ...mapStores(useDiscussionStore)
  },
  watch: {
    modelValue(value) {
      this.selectedUser = value;
    },
    selectedUser(value) {
      this.$emit('update:modelValue', value);
    },
    userQuery() {
      this.debounce?.cancel();
      this.debounce = debounce(() => {
        this.discussionStore.searchUser(this.userQuery).then((users) => {
          this.filteredUsers = users ?? [];
        });
      }, 250);
      this.debounce();
    },
  },
  mounted() {
    this.selectedUser = this.moduleValue;
  },
}
</script>