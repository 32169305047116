<template>
  <div
      class="bg-white border border-secondary dark:border-secondary-250 dark:bg-secondary-450 text-secondary dark:text-white rounded-md min-h-full max-w-screen p-9 flex flex-col gap-2">
    <!-- header -->
    <div class="text-2xl font-bold">Benutzer</div>

    <div class="text-sm text-gray-500 dark:text-white">
      Hinweis: Gelöschte Benutzer können nicht wiederhergestellt werden.
    </div>
    <progress v-show="loading" class="progress w-full"></progress>
    <div v-show="!loading" class="overflow-x-auto">
      <div class="inline-block min-w-full py-2 align-middle px-2">
        <div class="relative">
          <div v-if="selected.length > 0"
               class="absolute left-14 top-0 flex h-12 items-center space-x-3 sm:left-12">
            <button type="button"
                    class="inline-flex items-center rounded bg-white dark:bg-secondary-450 dark:text-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white">
              Auswahl löschen
            </button>
          </div>

          <!-- table | actual data -->
          <table class="min-w-full table-fixed divide-y divide-gray-300 dark:bg-transparent">
            <!-- header -->
            <thead>
            <tr>
              <!-- select all -->
              <th scope="col" class="relative px-7 sm:w-12 sm:px-6">
                <input type="checkbox"
                       class="cursor-pointer absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-secondary-450 dark:text-gray-50/30 focus:text-secondary-450"
                       :checked="indeterminate || selected.length === users.length"
                       :indeterminate="indeterminate"
                       @change="selected = $event.target.checked ? users.map((d) => d.id) : []"
                />

              </th>
              <th scope="col" class="py-3.5 text-left text-sm font-semibold text-secondary dark:text-white">
                Benutzername
              </th>
              <th scope="col" class="py-3.5 text-left text-sm font-semibold text-secondary dark:text-white">
                E-Mail
              </th>
              <th scope="col" class="py-3.5 text-left text-sm font-semibold text-secondary dark:text-white">
                Konversation
              </th>
              <th scope="col" class="py-3.5 text-left text-sm font-semibold text-secondary dark:text-white">
                Likes erhalten
              </th>
              <th scope="col" class="py-3.5 text-left text-sm font-semibold text-secondary dark:text-white">
                Likes vergeben
              </th>
              <th scope="col" class="py-3.5 text-left text-sm font-semibold text-secondary dark:text-white">
                Zuletzt eingeloggt
              </th>
              <th scope="col" class="py-3.5 text-left text-sm font-semibold text-secondary dark:text-white">
                Gesperrt
              </th>
              <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
            </thead>

            <!-- table body -->
            <tbody class="divide-y divide-gray-200">
            <tr v-for="(user, index) of users" :key="index"
                :class="[selected.includes(user.id) && 'bg-gray-50 dark:bg-gray-50/30', 'text-gray-500 dark:text-white']"
            >
              <!-- select box -->
              <td class="relative px-7 sm:w-12 sm:px-6">
                <div v-if="selected.includes(user.id)"
                     class="absolute inset-y-0 left-0 w-0.5 bg-primary dark:bg-white"></div>
                <input type="checkbox"
                       class="cursor-pointer absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-secondary-450 dark:text-gray-50/30 focus:text-secondary-450"
                       :value="user.id" v-model="selected"/>
              </td>

              <td class="px-2 py-4 text-sm">
                {{ user.username }}
              </td>

              <td class="px-2 py-4 text-sm">
                {{ user.email }}
              </td>

              <td class="px-2 py-4 text-sm">
                {{ user.discussions }}
              </td>

              <td class="px-2 py-4 text-sm">
                {{ user.likes }}
              </td>

              <td class="px-2 py-4 text-sm">
                {{ user.liked }}
              </td>

              <td class="px-2 py-4 text-sm">
                {{ user.lastLogin !== 0 ? format(user.lastLogin) : 'Niemals' }}
              </td>

              <td class="px-2 py-4 text-sm">
                <span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset" :class="[user.locked  ? 'bg-red-400/10 text-red-400 ring-red-400/20' : 'bg-green-500/10 text-green-400 ring-green-500/20']">{{ user.locked ? 'Ja' : 'Nein' }}</span>
              </td>

              <!-- edit -->
              <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                <router-link class="text-secondary dark:text-white dark:hover:text-primary-250 hover:text-primary"
                             :to="`/discussions/users/${user.id}`">
                  <i class="fa-regular fa-pen-to-square text-lg"></i>
                </router-link>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <nav v-if="false" class="flex justify-between items-center border-t border-gray-200 px-4 sm:px-0">
      <div class="text-sm text-gray-500 dark:text-white flex-grow pt-2">
        Seite {{ paging.current }} von {{ paging.pages }}
      </div>

      <div class="flex flex-grow justify-center">
        <!-- jump to first page -->
        <a v-if="paging.current > 1" href="#"
           class="inline-flex items-center border-t-2 border-transparent px-4 pt-2 text-sm font-medium text-gray-500 dark:text-white hover:border-primary hover:text-primary cursor-pointer"
           @click="paging.current = 1"
        >
          <i class="fa-regular fa-backward text-lg"></i>
        </a>

        <a
            v-for="rang of pagingRange"
            :key="rang"
            @click="paging.current = rang"
            :class="['inline-flex items-center px-4 pt-2 text-sm font-medium cursor-pointer border-t-2 hover:text-primary hover:border-primary select-none', rang === paging.current && 'border-primary text-primary', rang !== paging.current && 'border-transparent text-gray-500  dark:text-white']"
            aria-current="page">
          {{ rang }}
        </a>

        <!-- jump to last page -->
        <a v-if="paging.current < paging.pages" href="#"
           class="inline-flex items-center border-t-2 border-transparent px-4 pt-2 text-sm font-medium text-gray-500 dark:text-white hover:border-primary hover:text-primary cursor-pointer"
           @click="paging.current = paging.pages"
        >
          <i class="fa-regular fa-forward text-lg"></i>
        </a>
      </div>

      <!-- placeholder -->
      <div class="text-xs text-gray-500 flex-grow">
      </div>
    </nav>
  </div>

  <router-view></router-view>
</template>

<script>
import {mapStores} from "pinia";
import {useDiscussionStore} from "@/stores/discussion";
import moment from "moment-timezone";

export default {
  name: "UsersView",
  data() {
    return {
      loading: false,
      selected: [],
      users: [],
      paging: {
        current: 1,
        itemsPerSite: 20,
        pages: 10,
        total: 0,
      },
    }
  },
  computed: {
    ...mapStores(useDiscussionStore),
    indeterminate() {
      return this.selected.length > 0 && this.selected.length < this.users.length
    },
    pagingRange() {
      if (this.paging.current === 1) {
        if (this.paging.pages > 3) {
          return [1, 2, 3];
        } else {
          return [1, 2];
        }
      } else if (this.paging.current >= this.paging.pages) {
        return [this.paging.pages - 2, this.paging.pages - 1, this.paging.pages];
      } else {
        return [this.paging.current - 1, this.paging.current, this.paging.current + 1];
      }
    }
  },
  created() {
    this.loading = true;
    this.discussionStore.fetchUsers().then((users) => {
      this.users = users;

      this.paging.total = this.users.length;
      this.paging.pages = Math.ceil(this.users.length / this.paging.itemsPerSite);

      this.loading = false;
    });
  },
  watch: {
    'paging.current': {
      deep: true,
      handler() {
        this.loading = true;

        const start = (this.paging.current - 1) * this.paging.itemsPerSite;
        const end = start + this.paging.itemsPerSite;

        this.users = this.discussionStore.users.slice(start, end);

        this.loading = false;
      }
    },
  },
  methods: {
    format(time) {
      return moment(time).tz('Europe/Berlin').format('DD.MM.YYYY HH:mm');
    },
  }
}
</script>

<style scoped>

</style>