<template>
  <loading-component>
    <template #content>
      <div class="content-view-container">
        <h1 class="text-2xl font-bold pb-2">Formular „{{ form?.name }}“ editieren</h1>
        <upsert-form-component v-model="form" @upsert="update" mode="edit"/>
      </div>
    </template>
  </loading-component>
</template>

<script>
import {useFormsStore} from "@/stores/forms";
import {mapStores} from "pinia";
import UpsertFormComponent from "@/components/forms/UpsertFormComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import {useApplicationStore} from "@/stores/application";
import Notifications from "@/utility/notifications";

export default {
  name: "FormEditView",
  components: {LoadingComponent, UpsertFormComponent},
  props: ['id'],
  data() {
    return {
      loading: false,
      form: undefined
    }
  },
  async created() {
    this.applicationStore.setLoading(true);
    this.form = await this.formsStore.get(this.id);
    this.applicationStore.setLoading(false);
  },
  computed: {
    ...mapStores(useFormsStore, useApplicationStore)
  },
  methods: {
    update(form) {
      this.formsStore.update(form).then(() => {
        Notifications.success("Formular gespeichert");
      }).catch((error) => {
        // TODO: send toast
        console.error(error);
        Notifications.error("Das Formular konnte nicht gespeichert werden");
      });
    }
  }
}
</script>