<template>
  <div class="rounded-md h-full flex flex-col gap-2">
    <!-- stats -->
    <div class="grid grid-cols-3 rounded-md bg-white dark:bg-[#435968] text-secondary dark:text-white border border-secondary-450 dark:border-secondary-250 w-full">
      <!-- FORMS -->
      <div class="flex flex-col gap-1 p-4 border-r border-secondary-450 dark:border-secondary-250">
        <div class="text-lg dark:text-white font-bold">Formulareinsendungen</div>
        <div class="stat-value text-4xl dark:text-white">{{ submissionCount }}</div>
        <div class="text-sm text-secondary-350 dark:text-secondary-250">Insgesamt {{ formsStore.getForms?.length }} Formular(e)</div>
      </div>

      <!-- Kommentare -->
      <div class="flex flex-col gap-1 p-4 border-r border-secondary-450 dark:border-secondary-250">
        <div class="text-lg font-bold">Kommentare</div>
        <div class="stat-value text-4xl">{{ discussions.comments }}</div>
      </div>

      <!-- VG Wort Tokens -->
      <div class="flex flex-col gap-1 p-4">
        <div class="text-lg font-bold">VG Wort Tokens</div>
        <div class="stat-value text-4xl">{{ vgwortTokensCount }}</div>
      </div>
    </div>

    <!-- content -->
    <div class="bg-white border border-secondary dark:border-secondary-250 dark:bg-secondary-450 text-secondary dark:text-white rounded-md h-full p-9 flex flex-col gap-2">
      <div class="text-2xl font-bold">Hallo, {{authStore.getUser.name}}.</div>
    </div>
  </div>
</template>
<script>
import {useFormsStore} from "@/stores/forms";
import {mapStores} from "pinia";
import {useDiscussionStore} from "@/stores/discussion";
import {useAuthStore} from "@/stores/auth";

export default {
  name: 'OverviewView',
  data() {
    return {
      submissionCount: 0,
      discussions: {
        comments: 0,
        users: 0
      },
      vgwortTokensCount: 0
    }
  },
  computed: {
    ...mapStores(useFormsStore, useDiscussionStore, useAuthStore)
  },
  created() {
    this.formsStore.fetch();
    this.formsStore.fetchSubmissionCount().then((count) => {
      this.submissionCount = count;
    });
  }

}

</script>