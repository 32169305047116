<template>
  <div
      class="bg-white border border-secondary dark:border-secondary-250 dark:bg-secondary-450 text-secondary dark:text-white rounded-md min-h-full p-9 flex flex-col gap-2">
    <!-- header -->
    <div class="text-2xl font-bold">Discussions</div>

    <div class="flex flex-col">
      <!-- filter -->
      <div class="flex flex-col gap-2">
        <!-- filters -->
        <div class="grid grid-cols-3 gap-4">
          <!-- released or not -->
          <div>
            <div class="flex rounded-md shadow-sm">
              <div class="relative flex flex-grow items-stretch focus-within:z-10">
                <select id="types" name="types"
                        class="block w-full border-0 py-1.5 pl-3 pr-10 text-secondary ring-1 ring-inset ring-gray-300 focus:ring-2 focus:text-secondary-450 sm:text-sm sm:leading-6"
                        v-model="filter.selected.show"
                        @change="filter.selected.show = $event.target.value"
                >
                  <option v-for="(entry, index) of filter.show" :value="entry.key" :key=index>{{
                      entry.value
                    }}
                  </option>
                </select>
              </div>
              <button type="button"
                      class="relative -ml-px inline-flex items-center gap-x-1.5 px-3 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 bg-white hover:bg-secondary-250 hover:text-white"
                      @click="filter.selected.show = 'all'"
              >
                <i class="fa-regular fa-xmark"></i>
              </button>
            </div>
            <label for="types" class="block text-xs leading-6 text-gray-500 dark:text-white">
              Welche Konversationen sollen angezeigt werden?
            </label>
          </div>

          <!-- conversation type -->
          <div>
            <div class="flex rounded-md shadow-sm">
              <div class="relative flex flex-grow items-stretch focus-within:z-10">
                <select id="types" name="types"
                        class="block w-full border-0 py-1.5 pl-3 pr-10 text-secondary ring-1 ring-inset ring-gray-300 focus:ring-2 focus:text-secondary-450 sm:text-sm sm:leading-6"
                        v-model="filter.selected.type"
                        @change="filter.selected.type = $event.target.value"
                >
                  <option v-for="(entry, index) of filter.types" :value="entry.key" :key=index>{{
                      entry.value
                    }}
                  </option>
                </select>
              </div>
              <button type="button"
                      class="relative -ml-px inline-flex items-center gap-x-1.5 px-3 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 bg-white hover:bg-secondary-250 hover:text-white"
                      @click="filter.selected.tyoe = 'all'"
              >
                <i class="fa-regular fa-xmark"></i>
              </button>
            </div>
            <label for="types" class="block text-xs leading-6 text-gray-500 dark:text-white">
              Art der Konversation
            </label>
          </div>

          <!-- user -->
          <div>
            <user-search-component v-model="filter.selected.user"></user-search-component>

            <div v-if="false" class="flex rounded-md shadow-sm">
              <div v-if="false" class="relative flex flex-grow items-stretch focus-within:z-10">
                <select id="users" name="users"
                        class="block w-full border-0 py-1.5 pl-3 pr-10 text-secondary ring-1 ring-inset ring-gray-300 focus:ring-2 focus:text-secondary-450 sm:text-sm sm:leading-6"
                        v-model="filter.selected.user"
                        @change="filter.selected.user = $event.target.value"
                >
                  <option v-for="(user, index) of users" :value="user.id" :key=index>{{ user.username }}
                    ({{ user.email }})
                  </option>
                </select>
              </div>

              <Combobox as="div" class="flex-grow" v-model="filter.selected.user">
                <div class="relative">
                  <ComboboxInput
                      class="w-full border-0 py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                      @change="userQuery = $event.target.value"
                      :display-value="(user) => user.username ? `${user?.username} (${user?.email})` : ''"
                  />
                  <ComboboxButton
                      v-if="false"
                      class="absolute inset-y-0 right-0 flex items-center px-2 focus:outline-none">
                    <i class="fa-solid fa-chevron-down h-5 w-5 text-gray-500 text-xs pt-0.5"></i>
                  </ComboboxButton>

                  <ComboboxOptions v-if="filteredUsers.length > 0"
                                   class="bg-secondary-150 absolute z-10 mt-1 max-h-60 w-full overflow-auto text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <ComboboxOption v-for="user in filteredUsers" :key="user.id" :value="user" as="template"
                                    v-slot="{ active, selected }">
                      <li :class="['relative cursor-default select-none py-2 pl-3 pr-9 text-secondary', active ? 'bg-secondary-250' : '']">
                        <span :class="['block truncate', selected && 'font-semibold']">
                          {{ user.username }} ({{ user.email }})
                        </span>
                      </li>
                    </ComboboxOption>
                  </ComboboxOptions>
                </div>
              </Combobox>

              <button type="button"
                      class="relative -ml-px inline-flex items-center gap-x-1.5 px-3 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 bg-white hover:bg-secondary-250 hover:text-white"
                      @click="filter.selected.user = {}"
              >
                <i class="fa-regular fa-xmark"></i>
              </button>
            </div>
            <label for="users" class="block text-xs leading-6 text-gray-500 dark:text-white">
              Benutzer
            </label>
          </div>
        </div>

      </div>

      <!-- table -->
      <div class="mt-8 flow-root -m-4">
        <!-- search -->
        <div class="flex justify-end gap-2">
          <div class="w-96">
            <input type="text"
                   v-model="filter.selected.search"
                   class="block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                   placeholder="Suche"
            />
          </div>

          <button-component @click="$router.push('/discussions/create')">
          <span
              class="flex flex-shrink-0 items-center justify-center rounded-full mx-0 h-5 w-5"
          >
            <i class="fa-solid fa-plus text-sm"></i>
          </span>
          </button-component>
        </div>

        <!-- delete disclaimer -->
        <div class="text-sm text-gray-500 dark:text-white">
          Hinweis: Gelöschte Kommentare können nicht wiederhergestellt werden.
        </div>
        <progress v-show="loading" class="progress w-full"></progress>
        <div v-show="!loading" class="overflow-x-auto">
          <div class="inline-block min-w-full py-2 align-middle px-2">
            <div class="relative">
              <div v-if="selected.length > 0"
                   class="absolute left-14 top-0 flex h-12 items-center space-x-3 sm:left-12">
                <button type="button"
                        @click="deleteSelection()"
                        class="inline-flex items-center rounded bg-white dark:bg-secondary-450 dark:text-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                >
                  Auswahl löschen
                </button>
              </div>

              <!-- table | actual data -->
              <table class="min-w-full table-fixed divide-y divide-gray-300 dark:bg-transparent">
                <!-- header -->
                <thead>
                <tr>
                  <!-- select all -->
                  <th scope="col" class="relative px-7 sm:w-12 sm:px-6">
                    <input type="checkbox"
                           class="cursor-pointer absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-secondary-450 dark:text-gray-50/30 focus:text-secondary-450"
                           :checked="indeterminate || selected.length === discussions.length"
                           :indeterminate="indeterminate"
                           @change="selected = $event.target.checked ? discussions.map((d) => d.id) : []"
                    />

                  </th>
                  <th scope="col" class="py-3.5 text-left text-sm font-semibold text-secondary dark:text-white">Author
                  </th>
                  <th scope="col" class="py-3.5 px-2 text-left text-sm font-semibold text-secondary dark:text-white">
                    Konversation
                  </th>
                  <th scope="col" class="py-3.5 px-2 text-left text-sm font-semibold text-secondary dark:text-white">
                    Entity
                  </th>
                  <th scope="col" class="py-3.5 px-2 text-left text-sm font-semibold text-secondary dark:text-white">
                    Art
                  </th>
                  <th scope="col" class="py-3.5 px-2 text-left text-sm font-semibold text-secondary dark:text-white">
                    Erstellt
                  </th>
                  <th scope="col" class="py-3.5 px-2 text-center text-sm font-semibold text-secondary dark:text-white">
                    Freigegeben
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
                </thead>

                <!-- table body -->
                <tbody class="divide-y divide-gray-200">
                <tr v-for="(item, index) of discussions" :key="index"
                    :class="[selected.includes(item.id) && 'bg-gray-50 dark:bg-gray-50/30', 'text-gray-500 dark:text-white']"
                >
                  <!-- select box -->
                  <td class="relative px-7 sm:w-12 sm:px-6">
                    <div v-if="selected.includes(item.id)"
                         class="absolute inset-y-0 left-0 w-0.5 bg-primary dark:bg-white"></div>
                    <input type="checkbox"
                           class="cursor-pointer absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-secondary-450 dark:text-gray-50/30 focus:text-secondary-450"
                           :value="item.id" v-model="selected"/>
                  </td>

                  <!-- author -->
                  <td :class="['whitespace-nowrap pr-2 py-4 text-sm text-secondary dark:text-white', selected.includes(item.id) ? 'text-primary' : 'text-gray-900']">
                    <router-link
                        :to="`/discussions/users/${item.userId}`"
                    >
                      <p class="font-weight-bold">
                        {{ item.username }}
                      </p>
                    </router-link>

                    <p>
                      {{ item.email }}
                    </p>
                  </td>

                  <!-- discussion -->
                  <td class="px-2 py-4 text-sm min-w-26">
                    <p class="break-all">{{ item.discussion }}</p>
                  </td>

                  <!-- entity -->
                  <td class="px-2 py-4 text-sm w-12">
                    <a v-if="item.entity" :href="constructEntityUrl(item.entity)" target="_blank">
                      <p class="text-secondary dark:text-white break-all font-bold" v-html="item.entity.name"></p>
                      <p class="underline">{{ item.entity.id }}</p>
                      <p>{{ item.entity.type }}</p>
                    </a>
                    <p v-else>?</p>
                  </td>

                  <!-- type -->
                  <td class="whitespace-nowrap px-2 py-4 text-sm">
                    {{ getTypeLabel(item.type) }}
                  </td>

                  <!-- created -->
                  <td class="whitespace-nowrap px-2 py-4 text-sm">
                    {{ format(item.created) }}
                  </td>

                  <!-- release button -->
                  <td class="text-center">
                    <button @click="release(item.id, item.released = !item.released)">
                     <span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                           :class="[item.released  ? 'bg-green-500/10 text-green-400 ring-green-500/20': 'bg-red-400/10 text-red-400 ring-red-400/20']">
                       {{ !!item.released ? 'Ja' : 'Nein' }}
                     </span>
                    </button>

                    <input v-if="false"
                           type="checkbox"
                           class="cursor-pointer h-4 w-4 rounded border-gray-300 text-secondary-450 dark:text-gray-50/30 focus:text-secondary-450"
                           :value="item.released"
                           v-model="item.released"
                           @change="release(item.id, $event)"
                    />
                  </td>

                  <!-- misc -->
                  <td class="flex gap-2 items-center whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                    <!-- edit -->
                    <router-link class="text-secondary dark:text-white dark:hover:text-primary-250 hover:text-primary"
                                 :to="`/discussions/${item.id}`">
                      <i class="fa-regular fa-pen-to-square text-lg"></i>
                    </router-link>

                    <!-- answer -->
                    <router-link class="text-secondary dark:text-white dark:hover:text-primary-250 hover:text-primary"
                                 :to="`/discussions/create/${item.id}`">
                      <i class="fa-regular fa-comment text-lg"></i>
                    </router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- pagination -->
        <nav v-if="!loading" class="flex justify-between items-center border-t border-gray-200 px-4 sm:px-0">
          <div class="text-sm text-gray-500 dark:text-white flex-grow pt-2">
            Seite {{ paging.current }} von {{ paging.pages }}
          </div>

          <div class="flex flex-grow justify-center">
            <!-- jump to first page -->
            <a v-if="paging.current > 1" href="#"
               class="inline-flex items-center border-t-2 border-transparent px-4 pt-2 text-sm font-medium text-gray-500 dark:text-white hover:border-primary hover:text-primary cursor-pointer"
               @click="paging.current = 1"
            >
              <i class="fa-regular fa-backward text-lg"></i>
            </a>

            <a
                v-for="rang of pagingRange"
                :key="rang"
                @click="paging.current = rang"
                :class="['inline-flex items-center px-4 pt-2 text-sm font-medium cursor-pointer border-t-2 hover:text-primary hover:border-primary select-none', rang === paging.current && 'border-primary text-primary', rang !== paging.current && 'border-transparent text-gray-500  dark:text-white']"
                aria-current="page">
              {{ rang }}
            </a>

            <!-- jump to last page -->
            <a v-if="paging.current < paging.pages" href="#"
               class="inline-flex items-center border-t-2 border-transparent px-4 pt-2 text-sm font-medium text-gray-500 dark:text-white hover:border-primary hover:text-primary cursor-pointer"
               @click="paging.current = paging.pages"
            >
              <i class="fa-regular fa-forward text-lg"></i>
            </a>
          </div>

          <!-- placeholder -->
          <div class="text-xs text-gray-500 flex-grow">
          </div>
        </nav>
      </div>
    </div>
  </div>

  <router-view></router-view>
</template>

<script>
import ButtonComponent from "@/components/ButtonComponent.vue";
import {useDiscussionStore} from "@/stores/discussion";
import {mapStores} from "pinia";
import moment from "moment-timezone";
import {Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions,} from "@headlessui/vue";
import {debounce} from 'lodash';
import UserSearchComponent from "@/components/discussions/UserSearchComponent.vue";


export default {
  name: "DiscussionsView",
  components: {
    UserSearchComponent,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    Combobox,
    ButtonComponent,
    ComboboxButton
  },
  data() {
    return {
      loading: false,
      selected: [],
      filter: {
        selected: {
          show: 'all',
          type: 'all',
          entity: null,
          search: null
        },
        show: [
          {
            key: 'all',
            value: 'Alle'
          },
          {
            key: 'released',
            value: 'Freigegeben'
          },
          {
            key: 'unreleased',
            value: 'Nicht freigegeben'
          }
        ],
        types: [
          {
            key: 'all',
            value: 'Alle'
          },
          {
            key: 'comment',
            value: 'Kommentar'
          },
          {
            key: 'forum',
            value: 'Forum'
          }
        ]
      },
      paging: {
        current: 1,
        itemsPerSite: 20,
        pages: 10,
        total: 0,
      },
      discussions: [],
      users: [],
      userQuery: '',
      debounce: undefined,

      filteredUsers: []
    }
  },
  computed: {
    ...mapStores(useDiscussionStore),
    indeterminate() {
      return this.selected.length > 0 && this.selected.length < this.discussions.length
    },
    /*
    filteredUsers() {
      return this.userQuery === ''
          ? this.users
          : this.users.filter((user) => {
            return user.username.toLowerCase().includes(this.userQuery.toLowerCase()) || user.email.toLowerCase().includes(this.userQuery.toLowerCase())
          })
    },
     */
    pagingRange() {
      if (this.paging.current === 1) {
        if (this.paging.pages > 3) {
          return [1, 2, 3];
        } else {
          return [1, 2];
        }
      } else if (this.paging.current >= this.paging.pages) {
        return [this.paging.pages - 2, this.paging.pages - 1, this.paging.pages];
      } else {
        return [this.paging.current - 1, this.paging.current, this.paging.current + 1];
      }
    }
  },
  watch: {
    userQuery() {
      this.debounce?.cancel();
      this.debounce = debounce(() => {
        this.discussionStore.searchUser(this.userQuery).then((users) => {
          this.filteredUsers = users ?? [];
        });
      }, 250);
      this.debounce();
    },
    'filter.selected': {
      deep: true,
      handler(newValue) {
        // debounce search
        if (newValue.search) {
          this.debounce?.cancel();
          this.debounce = debounce(() => {
            this.loadByFilter();
          }, 250);
          this.debounce();
        } else {
          this.loadByFilter();
        }
      }
    },
    'paging.current': {
      deep: true,
      handler() {
        this.loadByFilter();
      }
    },
    '$route': {
      handler(value) {
        if (value.meta.refresh !== false)
          this.loadByFilter();
      },
      deep: true
    }
  },
  created() {
    this.loading = true;
    this.discussionStore.fetchUsers().then((users) => {
      this.users = users;
    });
    this.loadByFilter();
  },
  methods: {
    async loadByFilter() {
      this.loading = true;
      await this.discussionStore.loadByFilter({
        filter: [
          {
            key: 'released',
            value: this.filter.selected.show === 'all' ? 'ignore' : this.filter.selected.show === 'released'
          },
          {
            key: 'type',
            value: this.filter.selected.type
          },
          {
            key: 'entity',
            value: this.filter.selected.entity
          },
          {
            key: 'user',
            value: {
              id: this.filter.selected.user?.id,
            }
          },
          {
            key: 'search',
            value: this.filter.selected.search
          }
        ],
        properties: [
          {property: 'id'},
          {property: 'type'},
          {property: 'entity'},
          {property: 'released'},
          {property: 'accepted'},
          {property: 'created'},
          {
            property: 'user',
            properties: [
              {property: 'id'},
              //{property: 'type'},
              {property: 'username'},
              {property: 'email'},
              //{property: 'created'},
              //{property: 'lastLogin'}
            ]
          },
          /*
          {
            property: 'images',
            type: 'count'
          },
          */
          {
            property: 'likes',
            type: 'count'
          },
          {
            property: 'answers',
            type: 'count'
          },
          {
            property: 'discussion'
          }
        ],
        options: [
          {
            option: "relations",
            value: "true"
          },
          {
            option: "skip",
            value: this.paging.current - 1 === 0 ? 0 : this.paging.itemsPerSite * (this.paging.current - 1)
          },
          {
            option: "limit",
            value: this.paging.itemsPerSite
          },
          {
            option: "total",
            value: "true"
          }
        ]
      }).then((result) => {
        this.discussions = result.entities;
        this.paging.total = result.total;
        this.paging.pages = Math.ceil(this.paging.total / this.paging.itemsPerSite);

        this.loading = false;
      });
    },
    constructEntityUrl(entity) {
      return `${process.env.PLOETZBLOG ?? 'https://ploetzblog.de'}/${entity?.type === 'knowledge-graph' ? `detail/id=` : `?id=`}${entity?.id}`;
    },
    async deleteSelection() {
      this.loading = true;
      await this.discussionStore.delete(this.selected).catch(() => {
      });
      this.selected = [];
      await this.loadByFilter();
      this.loading = false;
    },
    /*
    async release(id, event) {
      await this.discussionStore.release(id, event.target.checked);
    },
     */
    async release(id, release) {
      await this.discussionStore.release(id, release);
    },
    format(time) {
      return moment(time).tz('Europe/Berlin').format('DD.MM.YYYY HH:mm');
    },
    getTypeLabel(type) {
      switch (type) {
        case 'comment':
          return 'Kommentar';
        case 'forum':
          return 'Forum';
        case 'qa':
          return 'Q&A';
      }
    }
  }
}
</script>