<template>
  <modal-component v-model="show">
    <template #body>
      <progress v-show="loading" class="progress w-full"></progress>
      <div v-if="!loading" class="text-center sm:text-left flex flex-col gap-4">
        <!-- title -->
        <div class="flex flex-col">
          <div class="text-xl font-bold">
            {{ parent ? `Antwort erstellen` : 'Konversation erstellen' }}
          </div>
        </div>

        <!-- parent discussion -->
        <div v-if="parent" class="">
          <p class="font-bold pb-1">Kommentar auf den geantwortet wird</p>
          <p class="text-gray-500 text-sm pb-1">{{ parentDiscussion.username}} ({{parentDiscussion.email}})</p>
          <div class="border p-4 rounded-sm">
            <p v-html="parentDiscussion.discussion"></p>
          </div>
        </div>

        <!-- entity -->
        <div class="flex flex-col gap-1">
          <div class="font-bold">Entity (Datensatz/Typo3 Seite) <span class="text-red-500">*</span></div>

          <!-- id -->
          <div class="flex -space-x-px">
            <div class="w-1/2 min-w-0 flex-1">
              <div class="flex rounded-md shadow-sm">
                <div class="relative flex flex-grow items-stretch focus-within:z-10">
                  <input type="text"
                         name="entity-id"
                         id="postal-code"
                         autocomplete="entity-id"
                         class="text-black dark:text-white relative block w-full rounded-none border-0 bg-transparent py-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-250 sm:text-sm sm:leading-6 disabled:text-gray-400"
                         placeholder="ID"
                         :value="parent ? parentDiscussion.entity.id : discussion.entity.id"
                         @change="discussion.entity.id = $event.target.value"
                         :disabled="parent"
                  >
                </div>
                <a type="button"
                   :href="`https://ploetzblog.de/${getEntity().type === 'knowledge-graph' ? `detail/id=${getEntity().id}` : `?id=${getEntity().id}`}`"
                   target="_blank"
                   class="cursor-pointer relative -ml-px inline-flex items-center gap-x-1.5 px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-250"
                >
                  <i class="fa-solid fa-arrow-up-right-from-square dark:text-white"></i>
                </a>
              </div>
            </div>
            <div class="min-w-0 flex-1">
              <input type="text"
                     name="name"
                     id="name"
                     autocomplete="name"
                     class="text-black dark:text-white relative block w-full rounded-none border-0 bg-transparent py-1.5 ring-1 ring-inset ring-gray-300 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-250 sm:text-sm sm:leading-6 disabled:text-gray-400"
                     placeholder="Datensatzname"
                     :value="parent ? parentDiscussion.entity.name : discussion.entity.name"
                     @change="discussion.entity.name = $event.target.value"
                     :disabled="parent"
              >
            </div>
            <div class="min-w-0 flex-1">
              <select id="entity-type"
                      name="entity-type"
                      autocomplete="entity-type"
                      class="text-black dark:text-white relative block w-full rounded-none border-0 bg-transparent py-1.5 ring-1 ring-inset ring-gray-300 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-250 sm:text-sm sm:leading-6 disabled:text-gray-400"
                      :value="parent ? parentDiscussion.entity.type : discussion.entity.type"
                      :disabled="parent"
                      @change="discussion.entity.type = $event.target.value"
              >
                <option selected value="knowledge-graph">Knowledge Graph</option>
                <option value="typo3">Typo3</option>
              </select>
            </div>
          </div>
        </div>

        <!-- author -->
        <div class="flex flex-col flex-grow">
          <div class="font-bold pb-1">Author <span class="text-red-500">*</span></div>
          <user-search-component v-model="discussion.author"></user-search-component>
        </div>

        <!-- discussion -->
        <div class="flex flex-col gap-1">
          <div class="font-bold">Konversation <span class="text-red-500">*</span> </div>

          <!-- tabs -->
          <div>
            <!-- writer tab -->
            <div v-show="view === 'write'" class="-m-0.5 rounded-lg p-0.5">
              <textarea rows="5"
                        name="comment"
                        id="comment"
                        class="bg-transparent text-secondary dark:text-white block w-full border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-100 sm:text-sm sm:leading-6"
                        placeholder="Kommentar hinzufügen"
                        v-model="discussion.discussion"
              ></textarea>
            </div>

            <!-- preview tab -->
            <div v-show="view === 'preview'" class="-m-0.5 p-0.5">
              <div
                  class="block p-2 w-full bg-transparent py-1.5 text-secondary dark:text-white border boder-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6">
                <discussion-preview :discussion="discussion"></discussion-preview>
              </div>
            </div>
          </div>

          <!-- tab control -->
          <div class="flex gap-2 justify-end" aria-orientation="horizontal" role="tablist">
            <button-component @click="view = 'write'" :selected="view === 'write'">
              Editieren
            </button-component>

            <button-component @click="view = 'preview'" :selected="view === 'preview'">
              Preview
            </button-component>
          </div>
        </div>

        <!-- discussion -->
        <div v-if="false" class="flex flex-col gap-2">
          <div class="text-lg font-bold">Bilder</div>
        </div>
      </div>
    </template>

    <template #footer>
      <button-component @click="show = false">
        Schließen
      </button-component>

      <button-component @click="save()">
        Speichern
      </button-component>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from "@/components/ModalComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import DiscussionPreview from "@/views/discussions/DiscussionPreview.vue";
import moment from "moment-timezone";
import {useDiscussionStore} from "@/stores/discussion";
import {mapStores} from "pinia";
import UserSearchComponent from "@/components/discussions/UserSearchComponent.vue";
import {useNotificationsStore} from "@/stores/notifications.store";

export default {
  name: "DiscussionCreateView",
  components: {UserSearchComponent, DiscussionPreview, ButtonComponent, ModalComponent},
  props: {
    parent: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      show: true,
      loading: true,
      view: 'write',
      discussion: {
        author: undefined,
        type: 'comment',
        created: Date.now(),
        entity: {
          id: undefined,
          name: undefined,
          type: 'knowledge-graph'
        },
        discussion: undefined,
      },
      parentDiscussion: undefined
    }
  },
  computed: {
    ...mapStores(useDiscussionStore, useNotificationsStore)
  },
  watch: {
    show(value) {
      if (!value)
        this.$router.push('/discussions');
    }
  },
  async created() {
    if (!this.parent) {
      this.loading = false;
      return;
    }

    this.discussionStore.fetch(this.parent).then((discussion) => {
      this.parentDiscussion = discussion;
      this.loading = false;
    }).catch(() => {
      this.$router.push('/discussions');
    });
  },
  methods: {
    save() {
      if (!this.discussion.discussion) {
        this.notificationsStore.push({
          type: "error",
          icon: "far fa-check-circle",
          title: "Fehler beim speichern",
          message: `Bitte fülle alle Pflichtfelder aus. Alle Pflichtfelder sind mit einem * markiert.`,
          duration: 10000,
        });
        return;
      }

      if (this.parentDiscussion) {
        this.discussion.parent = this.parentDiscussion.id;
        this.discussion.entity = this.parentDiscussion.entity;
        this.discussion.type = this.parentDiscussion.type;
      }

      this.discussionStore.create(this.discussion).then(() => {
        this.show = false;
        this.$router.push('/discussions');
      });
    },
    format(time, format = 'DD.MM.YYYY HH:mm') {
      return moment(time).locale('de').tz('Europe/Berlin').format(format);
    },
    getEntity() {
      return this.parent ? this.parentDiscussion.entity : this.discussion.entity;
    }
  }
}
</script>