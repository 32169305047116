import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";

import '@/assets/css/tailwind.css';
import '@/assets/scss/main.scss';
import {createPinia} from "pinia";
import Axios from "@/utility/axios";
import {useAuthStore} from "@/stores/auth";
import Notifications from "notiwind";

const pinia = createPinia();

console.log(process.env.NODE_ENV);
createApp(App)
    .use(pinia)
    .use(Notifications)
    .use(router)
    .mount("#pb__dashboard");

const authStore = useAuthStore();

Axios.axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log(error);
        if (
            error.response?.status === 401
        ) {
            if (!authStore.isRefreshing) {
                // get a new tokens
                authStore.refresh().then(() => {
                    location.reload();
                }).catch(() => {
                    location.href = authStore.getLoginUrl;
                });
            }
        }

        return Promise.reject(error);
    }
);
