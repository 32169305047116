/*
 * Written by Dominik Lippl <dl@venus.bayern> on 27.6.2023
 */

import {defineStore} from "pinia";
import axios from "axios";

export const useAuthStore = defineStore({
    id: "auth",
    state: () => ({
        client_id: process.env.VUE_APP_CLIENT_ID ?? "",
        redirect_uri: process.env.VUE_APP_REDIRECT_URI ?? "",

        access_token: localStorage.getItem("access_token"),
        id_token: localStorage.getItem("id_token"),
        refresh_token: localStorage.getItem("refresh_token"),

        refreshing: false,

        user: undefined
    }),
    getters: {
        isRefreshing: (state) => state.refreshing,
        isAuthenticated: (state) => !!state.access_token,
        getAccessToken: (state) => state.access_token,
        getIdToken: (state) => state.id_token,
        getRefreshToken: (state) => state.refresh_token,
        getUser: (state) => {
            if (!state.user && state.id_token)
                state.user = window.atob(state.id_token.split(".")[1]);

            return JSON.parse(state.user);
        },
        getLoginUrl: (state) => `${process.env.VUE_APP_OAUTH}/oauth2/authorize?client_id=${state.client_id}&redirect_uri=${state.redirect_uri}&response_type=code`,
        getSwitchAccountUrl: (state) =>
            `${process.env.VUE_APP_OAUTH}/oauth2/authorize?client_id=${state.client_id}&redirect_uri=${state.redirect_uri}&response_type=code&prompt=select_account`
    },
    actions: {
        authCallback(code) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_OAUTH}/oauth2/token`, {
                    client_id: this.client_id,
                    redirect_uri: this.redirect_uri,
                    grant_type: "authorization_code",
                    code
                }).then((response) => {
                    this.setData(response.data);
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        async refresh() {
            this.refreshing = true;

            return new Promise((resolve, reject) => {
                if (!this.isAuthenticated) {
                    reject("not logged in");
                    return;
                }

                axios.post(`${process.env.VUE_APP_OAUTH}/oauth2/token`, {
                    grant_type: "refresh_token",
                    client_id: this.client_id,
                    refresh_token: this.refresh_token
                })
                    .then((response) => {
                        this.refreshing = false;
                        this.setData(response.data);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        logout() {

        },
        setData(data) {
            const { access_token, id_token, refresh_token } = data;

            if (access_token) {
                localStorage.setItem("access_token", access_token);
                this.access_token = access_token;
            }

            if (id_token) {
                localStorage.setItem("id_token", id_token);
                this.id_token = id_token;
            }

            if (refresh_token) {
                localStorage.setItem("refresh_token", refresh_token);
                this.refresh_token = refresh_token;
            }

            this.getUser;
        },
        switchAccount() {
            location.href = this.getSwitchAccountUrl;
        },
    }
});