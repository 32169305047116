/*
 * Written by Dominik Lippl <dl@venus.bayern> on 27.6.2023
 */

import {defineStore} from "pinia";
import Axios from "@/utility/axios";


export const useFormsStore = defineStore({
    id: "forms",
    state: () => ({
        forms: undefined
    }),
    getters: {
        getForms: (state) => state.forms
    },
    actions: {
        async fetch() {
            return new Promise((resolve, reject) => {
                Axios.get('forms/fetch', "/api/forms").then((response) => {
                    this.forms = response.data;
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        async get(id) {
          if (this.forms) {
            const form = this.forms.find((f) => f._id === id);

            if (form)
                return form;
          }

          return Axios.get('forms/get', `/api/forms/${id}`).then((response) => {
            return response.data;
          });
        },

        async save(form) {
            return new Promise((resolve, reject) => {
                Axios.post('forms/save',  '/api/forms', form).then((response) => {
                    this.forms?.splice(this.forms.findIndex((f) => f._id === form._id), 1, response.data);
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        async update(form) {
            return new Promise((resolve, reject) => {
                Axios.patch('forms/update',  '/api/forms', form).then((response) => {
                    this.forms?.splice(this.forms.findIndex((f) => f._id === form._id), 1, response.data);
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        async delete(form) {
            return new Promise((resolve, reject) => {
                Axios.delete('forms/delete', `/api/forms/${form._id}`).then(() => {
                    this.forms?.splice(this.forms.findIndex((f) => f._id === form._id), 1);
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        async fetchSubmissionCount() {
            return new Promise((resolve, reject) => {
                Axios.get('forms/submission/count', "/api/forms/submissions/count").then((response) => {
                    resolve(response.data.count);
                }).catch((error) => {
                    reject(error);
                });
            });
        }
    }
});