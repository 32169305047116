<template>
  <div v-if="value" class="relative z-50 overflow-y-hidden text-secondary" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed inset-0 z-20 overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
          <div class="bg-white dark:bg-secondary-450 dark:text-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <slot name="body"></slot>
          </div>

          <!-- bg-gray-50 -->
          <div class="bg-primary-100 dark:bg-secondary-350 px-4 py-3 flex flex-wrap gap-4 sm:px-6 justify-between">
            <slot name="footer">
              <button type="button"
                      class="button"
                      @click="value = false"
              >
                Schließen
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalComponent",
  props: ["modelValue"],
  data() {
    return {
      value: false
    };
  },
  created() {
    this.value = this.modelValue;
  },
  watch: {
    modelValue(value) {
      this.value = value;
    },
    value(value) {
      this.$emit("update:modelValue", value);
    }
  }
};
</script>

<style scoped>

</style>