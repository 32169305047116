<template>
    <div class="absolute bottom-0 right-0 z-50 p-5" style="width: 500px">
        <div
            v-for="notification in notifications"
            :key="notification.id"
            class="py-3 px-4 rounded-md w-full text-white mt-5 flex items-center relative drop-shadow-lg"
            :class="{
                'bg-green-600': notification.type === 'success',
                'bg-red-600': notification.type === 'error',
                'bg-blue-500': notification.type === undefined,
                'slide-in': !notification.expired,
                'slide-out': notification.expired,
            }"
        >
            <div class="w-10 my-auto shrink-0">
                <i class="text-xl pl-1" :class="notification.icon"></i>
            </div>
            <div class="border-l-2 pl-4">
                <p class="font-bold mb-1">{{ notification.title }}</p>
                <p class="text-sm">{{ notification.message }}</p>
                <div
                    @click="notificationsStore.remove(notification.id)"
                    class="w-8 h-8 rounded-full flex justify-center items-center absolute top-2 right-2 hover:bg-white hover:text-red-500 cursor-pointer transition"
                >
                    <i class="far fa-close text-xl"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useNotificationsStore } from '@/stores/notifications.store';
import { mapStores } from 'pinia';

export default {
    name: 'NotificationSystem',
    computed: {
        ...mapStores(useNotificationsStore),
        notifications: function () {
            return this.notificationsStore.getNotifications;
        },
    },
};
</script>

<style scoped>
.slide-in {
    animation: slide-in 0.5s ease-out both;
}
.slide-out {
    animation: slide-out 0.5s ease-in both;
}

@keyframes slide-in {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0px);
        opacity: 1;
    }
    100% {
        transform: translateX(120px);
        opacity: 0;
    }
}
</style>
