import { defineStore } from 'pinia';
import _ from 'lodash';

export const useNotificationsStore = defineStore({
    id: 'notifications',
    state: () => ({
        notifications: [],
    }),
    getters: {
        getNotifications() {
            return this.notifications;
        },
    },
    actions: {
        push(payload) {
            const notification = {
                icon: 'fas fa-info-circle',
                ...payload,
                expired: false,
                id: _.uniqueId(),
            };
            this.notifications.push(notification);
            setTimeout(() => {
                this.remove(notification.id);
            }, notification.duration ?? 2000);
        },
        remove(id) {
            const notification = this.notifications.find((n) => n.id === id);
            notification.expired = true;
            setTimeout(() => {
                this.notifications = this.notifications.filter(
                    (n) => n.id !== id
                );
            }, 1000);
        },
    },
});
